const TOKEN_KEY = 'NIUTUTU_TOKEN'
export function SET_TOKEN(token: string) {
  localStorage.setItem(TOKEN_KEY, token)
}
export function GET_TOKEN() {
  return localStorage.getItem(TOKEN_KEY)
}
export function REMOVE_TOKEN() {
  localStorage.removeItem(TOKEN_KEY)
}

const REFRESH_TOKEN_KEY = 'NIUTUTU_REFRESH_TOKEN'
export function SET_REFRESH_TOKEN(token: string) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}
export function GET_REFRESH_TOKEN() {
  return localStorage.getItem(REFRESH_TOKEN_KEY)
}
export function REMOVE_REFRESH_TOKEN() {
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}
